




































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'handleBtn'
})
export default class extends Vue {
  @Prop({ default: 'add' }) private type!: string
  @Prop({ default: '' }) private btnTxt!: string
  @Prop({ default: '' }) private plain!: string
  @Prop({ default: '' }) private primary!: string
}
