
























































































































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { OptionList } from '@/api/common'
import { getFinancialAnalysisNext, getFinancialAnalysisInfo, getMoneyEdu } from '@/api/articles'
import { CommonMudule } from '@/store/modules/common'
import formItem from '@/components/formItem/index.vue'
import moduleHeader from '@/components/moduleHeader/index.vue'
import handleBtn from '@/components/handleBtn/index.vue'
import { familyStatus, libilityListStatus } from '@/types/data'
import { CusTomer, CustomerMember, CustomerMemberChildren, CreatModel, OptArr } from '@/types/kyc'
import { cloneDeep } from 'lodash'

const eduObj = {
  memberName: '',
  age: '',
  gender: '',
  customerEduList: [{
    eduType: 'Undergraduate',
    regionId: '',
    eduAmount: '',
    regionName: '',
    dicType: '',
    eduTypeName: '本科'
  }, {
    eduType: 'Master',
    regionId: '',
    eduAmount: '',
    regionName: '',
    dicType: '',
    eduTypeName: '研究生'
  }]
}
const itemObj = {
  memberName: '',
  age: '',
  gender: '',
  customerEduList: []
}

@Component({
  name: 'baseInfo',
  components: {
    formItem,
    moduleHeader,
    handleBtn
  }
})
export default class extends Vue {
 private creatModel:CreatModel = {
   customer: {
     id: '',
     customerName: '',
     familyStatus: '', // 家庭状况
     mateAge: '', // 配偶年龄
     children: '', // 孩子数量
     riskType: ''// 风险能力
   },
   customerMember: {
     age: '', // 年龄
     gender: '', // 性别
     nationId: '', // 国籍
     occupationId: '', // 职业
     inAmount: '',
     outAmount: '',
     inAmountPer: 0, // 年收入
     investAmountPer: 0 // 年支出
   },
   customerMemberChildren: [cloneDeep(itemObj)]
 }
  customerEduListObj:any = [{
    eduType: 'Undergraduate',
    regionId: '',
    eduAmount: '',
    regionName: '中国',
    eduTypeName: '本科',
    special: false,
    deleted: false,
    dicType: 'Region'
  }, {
    eduType: 'Master',
    regionId: '',
    eduAmount: '',
    regionName: '中国',
    eduTypeName: '研究生',
    special: false,
    deleted: false,
    dicType: 'Region'
  }]
 private labelPosition = 'right'
private sexItem: OptArr = [{
  label: '男',
  value: '0'
}, {
  label: '女',
  value: '1'
}]
 private defaultKey1 = {
   label: 'label',
   value: 'value'
 }
 private defaultKey2 = {
   label: 'dicName',
   value: 'id',
   key: 'index'
 }
 private defaultKey3 = {
   label: 'dicName',
   value: 'id',
   key: 'index'
 }
 private familyList = familyStatus
 private jobList = []
 private countryList = []
 private regionList = []
 private libilityList = libilityListStatus
// 绑定对象中的某个属性，只有当该属性发生变化时，触发该动作
@Watch('creatModel.customerMemberChildren', { immediate: false, deep: false })
 customerMemberChildren(newVal: any) {
   this.creatModel.customer.children = newVal.length
 }
changeVal() {
  if (this.creatModel.customer.familyStatus === 'SingleChildren' || this.creatModel.customer.familyStatus === 'SingleNoChildren') {
    this.creatModel.customerMember.inAmountPer = 100
  } else {
    this.creatModel.customerMember.inAmountPer = 0
  }
}
created() {
  this.initOption()
  this.initData()
  this.getChina()
}
async getChina() {
  const p1 = {
    eduType: 'Undergraduate',
    regionId: 'Region-id-0001'
  }
  const p2 = {
    eduType: 'Master',
    regionId: 'Region-id-0001'
  }
  const res = await Promise.all([
    getMoneyEdu(p1),
    getMoneyEdu(p2)
  ])
  if (res[0].data && res[1].data) {
    this.customerEduListObj[0].regionId = res[0].data.regionId
    this.customerEduListObj[0].eduAmount = res[0].data.eduAmount
    this.customerEduListObj[1].regionId = res[1].data.regionId
    this.customerEduListObj[1].eduAmount = res[1].data.eduAmount
  }
}
get getAdd() {
  return this.customerMemberChildrenList.length === 1
}
get customerMemberChildrenList() {
  const list = this.creatModel.customerMemberChildren.filter((item: any) => !item.deleted)
  return list
}
private async initData() {
  const res = await getFinancialAnalysisInfo({ customerId: CommonMudule.customerId })
  if (res.data) {
    if (res.data.customerMemberChildren.length > 0) {
      res.data.customerMemberChildren = res.data.customerMemberChildren.map((item:any) => {
        if (item.gender === 0 || item.gender) {
          item.gender = item.gender.toString()
        }
        if (item.customerEduList.length === 1) {
          item.customerEduList[1] = eduObj.customerEduList[1]
        }
        item.customerEduList.forEach((mt:any, index:any) => {
          if (mt.eduType === 'Undergraduate') {
            mt.eduTypeName = '本科'
          }
          if (mt.eduType === 'Master') {
            mt.eduTypeName = '研究生'
          }
          mt.regionObj = {
            id: mt.regionId,
            dicName: mt.regionName,
            index: mt.special
          }
        })
        return item
      })
    } else {
      res.data.customerMemberChildren = this.creatModel.customerMemberChildren
    }
    if (res.data.customerMember.gender === 0 || res.data.customerMember.gender) {
      res.data.customerMember.gender = res.data.customerMember.gender.toString()
    }
    res.data.customerMember.inAmount = Number(res.data.customerMember.inAmount) / 10000
    res.data.customerMember.outAmount = Number(res.data.customerMember.outAmount) / 10000
    this.creatModel = res.data
  }
}
async ageBlur(item:any, index1:any) {
  let list = []
  const create = cloneDeep(this.creatModel)
  list = create.customerMemberChildren.filter((item: any) => !item.deleted)
  if (item.age < 17 && item.age > 0) {
    if (item.id) {
      if (item.customerEduList.length === 0) {
        const obj = cloneDeep(eduObj)
        list[index1].customerEduList = obj.customerEduList
      } else {
        list[index1].customerEduList = item.customerEduList
      }
    } else {
      const obj = cloneDeep(eduObj)
      list[index1].customerEduList = obj.customerEduList
    }
    const deleteList = create.customerMemberChildren.filter((item: any) => item.deleted)
    create.customerMemberChildren = list.concat(deleteList)
  } else {
    create.customerMemberChildren[index1].customerEduList = []
  }
  this.creatModel = create
}
private async initOption() {
  const res = await Promise.all([
    OptionList({
      dicType: 'Occupation'
    }),
    OptionList({
      dicType: 'Nation'
    }),
    OptionList({
      dicType: 'Region'
    })
  ])

  this.jobList = res[0].data
  this.countryList = res[1].data
  this.regionList = res[2].data
}
private formatTooltip(val:any) {
  return parseInt(val) + '%'
}
private async submit() { // 点击下一步
  const value = this.$validator(this.$refs.ValidateForm)
  if (value) return
  const param = cloneDeep(this.creatModel)
  param.customer.children = this.creatModel.customerMemberChildren.length
  param.customerMember.gender = Number(param.customerMember.gender)
  const child = param.customerMemberChildren.filter((item) => { return item.age && item.memberName })
  param.customerMemberChildren = child
  if (param.customerMemberChildren.length > 0) {
    param.customerMemberChildren.map((item) => {
      item.gender = Number(item.gender)
      if (item.customerEduList.length === 0 && (item.age < 17 && item.age > 0)) {
        item.customerEduList = this.customerEduListObj
      }
      // if (item.customerEduList.length > 0) {
      //   item.customerEduList.forEach((mt:any, index:any) => {
      //     if (mt.regionObj && mt.regionObj.id) {
      //       mt.regionId = mt.regionObj.id
      //     }
      //     delete mt.regionObj
      //   })
      // }
      item.customerEduList.forEach((mt:any, index:any) => {
        delete mt.regionObj
      })
      return item
    })
  }
  param.customerMember.outAmount = Number(param.customerMember.outAmount) * 10000
  param.customerMember.inAmount = Number(param.customerMember.inAmount) * 10000
  const res = await getFinancialAnalysisNext(param)
  if (res.statusCode === 0 && res.data) {
    this.$router.push({
      path: '/portfolio/portkyc/analy'
    })
    const { customer } = res.data
    window.sessionStorage.setItem('customerName', customer.customerName)
    CommonMudule.SET_USER_NAME(customer.customerName)
    if (res.data.mateOrChildUpdate) {
      this.$message({
        message: '您所填写的家庭或子女信息已经发生改变,请重新填写您家庭成员的保险情况&信托分配情况,并同步更新必要的资产变更',
        type: 'success'
      })
    }
  } else {
    this.$message({
      message: res.errorMsg,
      type: 'error'
    })
  }
}
private addInfo() {
  const model = cloneDeep(itemObj)
  this.creatModel.customerMemberChildren.push(model)
}
private removeInfo(item:any, index:number) {
  if (this.creatModel.customerMemberChildren.length === 1) {
    this.$message({
      message: '至少保留一个',
      type: 'info'
    })
    return
  }
  if (!item.id) {
    this.creatModel.customerMemberChildren.splice(index, 1)
  } else {
    this.creatModel.customerMemberChildren.forEach((mt:any) => {
      if (mt.id === item.id) {
        mt.deleted = true
      }
    })
    const temp = this.creatModel.customerMemberChildren
    this.creatModel.customerMemberChildren = []
    this.creatModel.customerMemberChildren = temp
  }
}
private async changeSelect(item:any, index1:any, index2:any) {
  if (item.regionObj.dicSpecial === 'Others') {
    if (item.id) {
      (this as any).creatModel.customerMemberChildren[index1].customerEduList[index2].special = true;
      (this as any).creatModel.customerMemberChildren[index1].customerEduList[index2].eduAmount = '';
      (this as any).creatModel.customerMemberChildren[index1].customerEduList[index2].regionName = '';
      (this as any).creatModel.customerMemberChildren[index1].customerEduList[index2].regionId = item.regionObj.id
    } else {
      (this as any).creatModel.customerMemberChildren[index1].customerEduList[index2].special = true;
      (this as any).creatModel.customerMemberChildren[index1].customerEduList[index2].eduAmount = '';
      (this as any).creatModel.customerMemberChildren[index1].customerEduList[index2].regionName = '';
      (this as any).creatModel.customerMemberChildren[index1].customerEduList[index2].regionId = item.regionObj.id
    }
  } else {
    let list = []
    const create = cloneDeep(this.creatModel)
    list = create.customerMemberChildren.filter((item: any) => !item.deleted)
    // @ts-ignore
    list[index1].customerEduList[index2].special = false
    if (item.regionObj.id) {
      const res = await getMoneyEdu({ eduType: item.eduType, regionId: item.regionObj.id })
      if (res.data) {
        list[index1].customerEduList[index2].regionId = item.regionObj.id
        list[index1].customerEduList[index2].regionName = item.regionObj.dicName
        list[index1].customerEduList[index2].dicType = item.regionObj.dicType
        list[index1].customerEduList[index2].eduAmount = res.data.eduAmount
        const deleteList = create.customerMemberChildren.filter((item: any) => item.deleted)
        create.customerMemberChildren = list.concat(deleteList)
        this.creatModel = create
      } else {
        this.$message({
          message: '请求失败',
          type: 'error'
        })
      }
    } else {
      this.creatModel.customerMemberChildren[index1].customerEduList[index2].regionId = ''
      this.creatModel.customerMemberChildren[index1].customerEduList[index2].regionName = ''
      this.creatModel.customerMemberChildren[index1].customerEduList[index2].eduAmount = ''
    }
  }
}
}
