<template>
  <div class="page-container">
    <module-header title="基础家庭信息" />
    <div>
      <base-info />
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import baseInfo from './template/baseInfo'
@Component({
  name: 'financialAnalysis',
  components: {
    moduleHeader,
    baseInfo
  }
})
export default class extends Vue {

}
</script>
